@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

.main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  padding: 10px;
  padding-top: 90px;
  background: linear-gradient(60deg, #ffd256, #ffd559, #fda23a);
  background-size: 600% 600%;
  animation: wave 10s ease infinite;
}

@keyframes wave {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.main-container#leader{
  width: -webkit-fill-available;
}

.profile-container {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  justify-content: center;
}

.board-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
}

.leader-board{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}

h2#leader{
  padding-top: 30px;
  margin-bottom: 0px;
}

.account-box {
  background-color: #F6F6F6;
  border-radius: 1rem;
  margin: 3rem 1rem 3rem 1rem;
  padding: 1.8rem;
  width: -webkit-fill-available;
}

.account-box#leader{
  padding-bottom: 15px;
}

.interests-box {
  background-color: #F6F6F6;
  border-radius: 1rem;
  margin: 0 1rem 3rem 1rem;
  padding: 1.8rem;
}

.left-profile {
  display: flex;
  flex-direction: column;
  min-width: 600px;
}

.top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.box-name {
  font-size: 1rem;
  color: #939393;
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width:350px;
}

.name-style {
  font-family: Nunito;
  font-weight: bold;
  font-size: 2.8rem;
}

.name-style2 {
  font-family: Nunito;
  font-weight: bold;
  font-size: 2rem;
  padding-left: 20px;
  padding-right: 40px;
  color:black;
}

.score-style{
  font-family: Nunito;
  font-size: 2rem;
  color: #565656;
  padding-right: 30px;
}

.email-style {
  color: #565656;
  font-family: Nunito;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.other-style {
  color: #565656;
  font-family: Nunito;
  font-size: 1.2rem;
}

.connection-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-family: 'Nunito', sans-serif;
}

.chips-container#invite{
  align-items: center;
  justify-content: center;
}

.chips-container span{
  font-family: 'Nunito', sans-serif;
  font-size: 1rem;
}

.right-profile {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  width: 100%;
}

.edit-form {
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.edit{
  padding-top:100px;
  background-color: rgb(221, 221, 221);
}

button#invite{
  margin-right: 20px;
}

@media(max-width: 1024px) {

  .main-info{
    min-width: unset;
  }
}

@media (max-width: 768px) {

  .main-container {
    align-items: center;
  }

  .account-box {
    width: 250px;
  }

  .account-box#leader{
    width: 80%;
  }

  .account-box#right{
    margin-top: 0;
    margin-bottom: 20px;
  }

  .profile-container {
    flex-direction: column;
    align-items: center;
  }

  .interests-box {
    max-width: 250px;
  }

  .left-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: unset;
    min-width:unset;
  }

  .right-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: unset;
    margin: 0;
    padding: 0;
  }

  .name-style {
    font-size: 2.4rem;
  }

  .name-style2 {
    font-size: 1rem;
    padding-left: 0;
  }

  .score-style{
    font-size: 1rem;
    padding-right: 0;
  }

  .email-style{
    font-size: 1.3rem;
  }

  .main-info{
    min-width: unset;
  }

  h2#leader{
    font-size: 30px;
  }

  .link-buttons{
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  button#invite{
    margin-bottom: 20px;
    margin-right: 0px;
  }

}