@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

/* youtube */
.gradient-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #F31C1C, #581646);
    color: white;
    padding: 20px;
}

/* success */

.gradient-background-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    justify-content: center;
    padding: 20px;
    padding-top: 90px;
    color:white;
    
    /* Gradient Background */
    background-size: 200% auto;
    background-image: 
        linear-gradient(202deg, rgb(248 129 0 / 82%), rgba(0, 255, 0, 0.023) 70.71%),
        linear-gradient(119deg, rgb(200, 72, 211), rgb(0 255 0 / 0%) 70.71%),
        linear-gradient(88deg, rgb(163, 47, 206), rgb(0 255 0 / 0%) 70.71%),
        linear-gradient(331deg, rgb(0 56 255), rgb(0 255 0 / 0%) 70.71%);
    
    /* Animation */
    animation-name: gradientChange;
    animation-duration: 5s; 
    animation-iteration-count: infinite; 
}

@keyframes gradientChange {
    0% { background-position: 0% 50%; }
    50% { background-position: 40% 30%; }
    100% { background-position: 0% 50%; }
}


/* spotify */
.gradient-background-3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #49e668, #112e20);
    color: white;
    padding: 20px;
}

/* success */
.register-btn {
    display: flex;
    align-items: center;
    background-color: #00a6ff;
    color: rgb(255, 255, 255);
    border-color: transparent;
    border-radius: 5px;
    padding: 15px 30px;
    margin-top:30px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
  }

    .register-btn:hover {
        background-color: #29b4ff;
        color: white;
    }

/* registration */
.gradient-background-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #DD1F1F, #DF813D);
    color: white;
    padding: 20px;
    padding-top: 120px;
}

@keyframes animatedBackground {
    0% { background: #F31C1C; }
    50% { background: #21e448; }
    100% { background: #F31C1C; }
}

/* Connect Socials (combined) */
.gradient-background-5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #F31C1C, #49e668);
    color: white;
    padding: 20px;
    animation: animatedBackground 7s infinite;
    padding-top: 90px;
}

@keyframes animatedBackground2 {
    0% { background: #1c8bf3; }
    50% { background: #2165e4; }
    100% { background: #4dc3ee; }
}

.gradient-background-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #641cf3, #2651dc);
    color: white;
    padding: 20px;
    animation: animatedBackground2 7s infinite;
    padding-top: 90px;
}

.gradient-background-7{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #4dc3ee;
    color: white;
    padding: 20px;
    padding-top: 90px;
}

.styled-button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
}

h1 {
    font-size: 60px;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    /* text-transform: uppercase; */
    text-align: center;
}

h2{
    font-size: 40px;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    /* text-transform: uppercase; */
    text-align: center;

}

p {
    margin-top: 0;
    font-weight: bold;
    font-size: 25px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
}

#skip{
    margin-top: 20px;
    /* text-decoration: underline; */
    font-family: 'Nunito', sans-serif;
    font-size: 30px;
}


button{
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    color:black;
}

/* Connect Page */
.message{
    position: absolute;
    top: 220px;
    animation: descend 2s forwards;
}

.message p{
    background-color: rgb(255, 255, 255);
    padding: 10px 15px;
    margin-bottom:0px;
    border-radius: 10px;
    color: black;
}

@keyframes descend {
    0% {
      transform: translateY(-15%);
      opacity: 0;
    }
    40% {
      transform: translateY(0);
      opacity: 1;
    }
    60% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15%);
      opacity: 0;
    }
  }

/* used in: RegistrationForm */
.centered-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Nunito', sans-serif;
}

.centered-text button{
    /* margin-top: 20px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

#location {
    display: block;
    /* margin-right: 20px; */
    padding: 15px 30px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
}

#select{
    display: block;
    padding: 10px 30px;
    border-radius: 10px;
    border: none;
    background: white;
    cursor: pointer;
    margin-top:20px;
}

input::placeholder{
    color: white;
}

.progress-window{
    width: 400px;
    background-color: #ddd;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.progress-bar{
    height: 20px;
    background-color: #FFBF00;
    border-radius: 10px;
    transition: "width 0.5s ease-in-out";
}

/* used in location */
ul{
    list-style-type: none;
    padding: 0;
    max-height: 200px;
    overflow-y: auto;
    width: 350px;
    border-radius: 15px;
    margin: 20px;
    scrollbar-width: none;
}

li{
    padding: 10px 15px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    cursor: pointer;
    color:black;
}

.age-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}


/* used in AgeForm, IntentForm, InterestForm, Connect Socials */
.gender-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    padding-bottom: 40px;
}

.gender-container button{
    display: flex;
    padding: 50px 50px;
    margin: 17px 20px;
    font-size: 30px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background: white;
}

/* .gender-container button:hover{
    background-color: rgb(235, 235, 235);
} */

.gender-container select{
    width: 250px;
    height: 90px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    border-radius: 10px;
    border: none;
    margin-left: 5px;
}

.gender-container select option{
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gender-container input{
    border: none;
    padding: 30px;
    border-radius: 5px;
    font-family: 'Nunito';
    width: 40%;
    display: flex;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
}

input[type="date"] {
    display: flex;
    justify-content: center; /* Adjust this value as needed */
}

#social button{
    padding: 0%;
}

/* used in Interests */
.gender-container .button-selected {
    /* opacity: 0.8; */
    background-color: #ffda6d;
    /* background-color: rgb(52, 190, 250); */
}

.gender-container .button-unselected {
    opacity: 1;
}

.button-disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

  /* used in RegistrationForm */
  .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }
  
  .prev-button, .next-button, .submit-button {
    border: none;
    outline: none;
    background: transparent;
    font-size: 1.5em; /* adjust as needed */
    color: white;
    cursor: pointer;
    padding: 20px;
  }
  
  .prev-button {
    align-self: flex-start;
    font-size: 30px;
  }
  
  .next-button, .submit-button {
    align-self: flex-end;
    font-size: 30px;
  }

.phone-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.phone-container select{
    height: 45px;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    text-align: center;
    border: none;
    background: transparent;
    margin-left: 5px;
    margin-right: 15px;
    color: white;
}

.phone-container select option{
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
}

.phone-container input{
    border: none;
    border-bottom: 1.5px solid rgb(255, 255, 255);
    background: transparent;
    outline: none;
    width: 200px;
    height: 40px;
    color: white;
    font-size: 25px;
    font-family: 'Nunito', sans-serif;
}

.name-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 160px;
    padding-right: 160px;
}

.name-container label{
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    /* text-transform: uppercase; */
    text-align: left;
    margin-top: 50px;
    width: 300px;
    font-size: 20px;
}

.name-container input{
    border: none;
    border-bottom: 1.5px solid rgb(255, 255, 255);
    background: transparent;
    outline: none;
    width: 300px;
    height: 40px;
    color: white;
    font-size: 20px;
    font-family: 'Nunito', sans-serif;
}

.name-container input::placeholder{
    color: white;
}



@media (max-width: 600px) {
  
    h1 {
      font-size: 70px;
    }

    .styled-button {
      padding: 20px 40px;
        font-size: 20px;
        border-radius: 15px;
        font-weight: bold;
    }

    .message{
        top:250px;
    }

    #skip{
        margin-top: 0px;
    }

    .gender-container button{
        display: flex;
        padding: 50px 50px;
        margin: 10px 10px;
        
    }

    #interests button{
        padding: 10px;
        width: 150px;
        height: 150px;
        font-size: 20px;
        display: flex;
    }

    #interests button #text{
        font-size: 10px;
    }

    .name-container{
        padding: 0px;
    }

    #location{
        margin-right: 0px;
        border-radius: 10px;
    }

    .progress-window{
        width: 300px;
    }
  }