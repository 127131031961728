@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: white;
  }
  
  .privacy-policy h1 {
    color: #ffffff;
    font-size: 50px;
    font-weight: bolder;
    margin: 20px;
    padding: 20px;
  }

.privacy-policy h2 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
}
  
  .privacy-policy p, .privacy-policy ol {
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .privacy-policy a {
    color: #5ad9ff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }

  li{
    font-family: 'Nunito', sans-serif;
    color:#ffffff;
  }

  /* TOS */


  .tos {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  .tos h1, .tos h2 {
    color: #ffffff;
    font-size: 50px;
    padding-top: 20px;
  }

  .tos h3{
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bolder;
  }
  
  .tos p, .tos ol {
    font-size: 18px;
    margin-top: 16px;
    margin-bottom: 20px;
    font-weight: 400;
  }
  
  .tos a {
    color: #007bff;
    text-decoration: none;
  }
  
  .tos a:hover {
    text-decoration: underline;
  }

  .tos p #contact{
  text-align: center;
  font-size: 20px;
  }

  /* About */

  #about, #about h2 {
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
  }

  #about h1{
    margin-bottom: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    .privacy-policy p{
      font-size: 18px;

    }

    .privacy-policy{
      padding: 25px;
    }

    .tos{
      padding: 35px;
    }

    .tos p{
      text-align: left;
    }

    .tos p#contact{
      text-align: center;
      font-size: 20px;
    }

    #about h2{
      font-size: 30px;
    }

    #about{
      padding: 30px;
    }

    #about h1{
      flex-direction: column;
    }


  }