/* LoginForm.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap');

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to bottom, #1CA5F3, #6E237A);
  color: white;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  padding: 40px;
}

.container-2 {
  display: flex;
  flex-direction: column;
  padding: 100px 180px;
  background: linear-gradient(to bottom, #1CA5F3, #6E237A);
  color: white;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

.container-2 p {
  text-align: center;
  padding: 10px 0px;
}

.google-btn {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
}

.google-btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

h1 {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}

/* button svg {
  width: 30px;
  padding-right: 10px;
} */

button#skip{
  border:none;
  margin-top: 40px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  background-size: 200% auto;
  background-image: linear-gradient(to right, rgb(252, 255, 102)50%, transparent 50%);
  border-radius: 10px;
  padding: 5px 14px;
  transition: background-position 0.2s;
  background-position: right center;
  font-size: 25px;
}

button#skip:hover{
  /* text-decoration: underline; */
  background-position: left center;
  color: rgb(0, 0, 0);
}

@keyframes fadeIn {
  0% {opacity: 0;
  transform: translatey(-10px);}
  100% {opacity: 1;
  transform: translatey(0px);}

}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

.fade-in2 {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

/* Separator */

.separator {
  background-color: black;
  height: 120px; /* adjust as needed */
  width: 100%;
}

/* Footer */

.footer-container {
  background-color: #000000;
  padding: 12px 0;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
}

.footer-container p{
  margin-bottom:5px;
  font-size: 1em;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 3px;
  font-size: 12px;
}

.footer-links a {
  color: #007bff;
  text-decoration: none;
}

.ocean {
  height: 30%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #015871;
  z-index: -1;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 100vh;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.wave:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}

@keyframes wave {
  0% { margin-left: 0; }
  100% { margin-left: -1600px;}
}
@keyframes swell {
  0%, 100% {
    transform: translate(0, -30px);
  }
  50% {
    transform: translate(0, 5px);
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 3em;
  }

  /* p {
      font-size: 1em;
    } */

  .google-btn {
    font-size: 0.8em;
  }

  .container-2 p{
    font-size: 22px;
  }

  .separator{
    height: 60px;
  }

  .container-2{
    padding: 80px 50px;
  }
}