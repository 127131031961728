@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    margin-bottom: 30px;
    background: rgba(84, 84, 84, 0.1); /* Semi-transparent white */
    backdrop-filter: blur(5px); /* Apply a blur to the backdrop */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Add a box-shadow */
}

.header p{
    align-items: end;
    font-size: 20px;
    margin: 0;
}

.header h1{
    font-size: 50px;
    font-family: 'Nunito', sans-serif;
}

a{
    text-decoration: none;
    color: white;
}

.navbar{
font-family: 'Nunito', sans-serif;
display: flex;
justify-content: flex-end;
align-items: center;
flex:1;
margin-right: 40px;
}

.navbar-logo {
    height: 50px; /* adjust as needed */
    margin-left: 10px; /* adjust as needed */
    cursor: pointer;
  }

.navbar-logo#text{
    height:45px;
}  

#profile img{
    cursor: pointer;
    width: 50px;
    border-radius: 50%;
    border: 2px solid transparent;
}

#profile select{
    border: none;
}

#profile img:hover{
    border: rgb(139, 139, 139) 2px solid;
}

.dropdown{
    position:absolute;
    background-color: white;
    z-index: 1;
    margin-top: 15px;
    margin-left: -90px;
    border-radius: 20px;
    width: 170px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

#dd{
    display: flex;
    width: 100%;
    padding: 20px 28px;
    border: none; /* Remove the default button border */
    background: none; /* Remove the default button background */
    text-align: left; /* Align the text to the left */
    cursor: pointer; /* Change the cursor when hovering over the button */
}

#dd svg{
    margin-right: 15px;
}

#dd:hover{
    background-color: #f2f2f2;
    border-radius: 20px;
}

@media (max-width: 600px) {
    .header h1{
        font-size: 30px;
    }
    .header p{
        font-size: 15px;
    }
    .navbar-logo {
        height: 35px; /* adjust as needed */
        margin-right: 10px; /* adjust as needed */
        margin-left: 5px; /* adjust as needed */
        cursor: pointer;
      }

    .navbar-logo#text{
        height:35px;
    }  

    .navbar{
        margin-right: 30px;

    }
    #profile img{
        width:40px;
    }

    .dropdown{
        width:150px;
    }

    #dd svg{
        width: 15px;
    }
}

